import { reservationHoursValidation } from '@gts-ft/ui';

import { Formik } from 'formik';
import { useState } from 'react';
import { ReservationInterval } from '@gts-ft/utils';
import { RestaurantReservationTimesState } from '../../../types';
import { ReservationHours } from '../../RestaurantOpeningTimes/ReservationHours';
import { NavButtons } from '../NavButtons';
import {
  Actions,
  SETUP_WIZARD_NEXT_STEP,
  SETUP_WIZARD_PREVIOUS_STEP,
} from '../../../actions/reduxActionTypes';

interface Props {
  reservationTimes: RestaurantReservationTimesState;
  execRestaurantReservationTimesChange: (
    openingTimes: RestaurantReservationTimesState,
    action: Actions,
  ) => void;
}

export const ReservationTimesStep = ({
  reservationTimes,
  execRestaurantReservationTimesChange,
}: Props) => {
  const [reservationHours, setReservationHours] = useState(
    reservationTimes.reservationHours,
  );

  function addReservationInterval(reservationInterval: ReservationInterval) {
    setReservationHours(
      [...reservationHours, reservationInterval].sort((a, b) =>
        a.startTime <= b.startTime ? -1 : 1,
      ),
    );
  }

  function deleteReservationInterval(index: number) {
    setReservationHours(reservationHours.filter((_interval, i) => i !== index));
  }

  return (
    <>
      <Formik
        onSubmit={({ startTime, endTime }, { resetForm }) => {
          addReservationInterval({
            startTime: (startTime as unknown) as number,
            endTime: (endTime as unknown) as number,
          });
          resetForm();
        }}
        initialValues={{
          startDay: undefined,
          startTime: undefined,
          endTime: undefined,
        }}
        validationSchema={reservationHoursValidation}
      >
        <ReservationHours
          explanationText="Hier definieren Sie die Uhrzeiten bzw. Zeiträume, wo Ihre Gäste ein
          Tisch über das Reservierungsformular reservieren können. Wenn Sie
          Reservierungen nur zu einer bestimmte Uhrzeit erlauben wollen, müssen
          Startzeit und Endzeit gleich sein."
          reservationHours={reservationHours}
          deleteInterval={deleteReservationInterval}
        />
      </Formik>
      <NavButtons
        backClicked={() => {
          execRestaurantReservationTimesChange(
            {
              reservationHours,
              closedOnDates: [],
            },
            { type: SETUP_WIZARD_PREVIOUS_STEP },
          );
        }}
        nextClicked={() => {
          execRestaurantReservationTimesChange(
            {
              reservationHours,
              closedOnDates: [],
            },
            { type: SETUP_WIZARD_NEXT_STEP },
          );
        }}
      />
    </>
  );
};
