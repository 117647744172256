import {
  reservationHoursValidation,
  closedOnDatesValidation,
} from '@gts-ft/ui';
import { SaveButton, View } from '@gts-common/client';

import { Formik } from 'formik';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';
import { format, isBefore, parse } from 'date-fns';
import { ReservationInterval } from '@gts-ft/utils';
import { RestaurantReservationTimesState } from '../../types';
import { HOME_VIEW } from '../../constants';
import { ReservationHours } from './ReservationHours';
import { ClosedOnDates } from './ClosedOnDates';

interface Props {
  openingTimes: RestaurantReservationTimesState;
  isOnline: boolean;
  execRestaurantOpeningTimesChange: (
    openingTimes: RestaurantReservationTimesState,
  ) => void;
}

export const RestaurantOpeningTimes = ({
  openingTimes,
  isOnline,
  execRestaurantOpeningTimesChange,
}: Props) => {
  const [reservationHours, setReservationHours] = useState(
    openingTimes.reservationHours,
  );

  const [closedDates, setClosedDates] = useState(
    openingTimes.closedOnDates.map((dateString) =>
      parse(dateString, CLIENT_DATE_FORMAT, new Date()),
    ),
  );

  function addReservationInterval(reservationInterval: ReservationInterval) {
    setReservationHours(
      [...reservationHours, reservationInterval].sort((a, b) =>
        a.startTime <= b.startTime ? -1 : 1,
      ),
    );
  }
  function deleteReservationInterval(index: number) {
    setReservationHours(reservationHours.filter((_interval, i) => i !== index));
  }

  function addClosedOnDate(closedDate: string) {
    setClosedDates(
      [
        ...closedDates,
        parse(closedDate, CLIENT_DATE_FORMAT, new Date()),
      ].sort((a, b) => (isBefore(a, b) ? -1 : 1)),
    );
  }

  function deleteClosedDate(index: number) {
    setClosedDates(closedDates.filter((_date, i) => i !== index));
  }

  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Reservierungszeiten"
    >
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={6}>
          <Formik
            onSubmit={({ startTime, endTime }, { resetForm }) => {
              addReservationInterval({
                startTime: (startTime as unknown) as number,
                endTime: (endTime as unknown) as number,
              });
              resetForm();
            }}
            initialValues={{
              startDay: undefined,
              startTime: undefined,
              endTime: undefined,
            }}
            validationSchema={reservationHoursValidation}
          >
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Reservierungszeiten</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ReservationHours
                  explanationText="Hier definieren Sie die Uhrzeiten bzw. Zeiträume, wo Ihre Gäste ein
        Tisch über das Reservierungsformular reservieren können. Wenn Sie
        Reservierungen nur für eine bestimmte Uhrzeit erlauben wollen, müssen
        Startzeit und Endzeit gleich sein. Wenn Sie Änderungen vorgenommen
        haben, müssen Sie zusätzlich noch auf Speichern klicken."
                  reservationHours={reservationHours}
                  deleteInterval={deleteReservationInterval}
                />
              </AccordionDetails>
            </Accordion>
          </Formik>
        </Grid>
        <Grid item xs={12} md={6}>
          <Formik
            onSubmit={({ closedDate }, { resetForm }) => {
              addClosedOnDate((closedDate as unknown) as string);
              resetForm();
            }}
            initialValues={{ closedDate: undefined }}
            validationSchema={closedOnDatesValidation}
          >
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Geschlossene Tage</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ClosedOnDates
                  closedDates={closedDates}
                  deleteClosedDate={deleteClosedDate}
                />
              </AccordionDetails>
            </Accordion>
          </Formik>
        </Grid>
      </Grid>
      <Box mt="1rem" textAlign="center">
        <SaveButton
          disabled={!isOnline}
          onClick={() =>
            execRestaurantOpeningTimesChange({
              closedOnDates: closedDates.map((date) =>
                format(date, CLIENT_DATE_FORMAT),
              ),
              reservationHours,
            })
          }
        />
      </Box>
    </View>
  );
};
